import React from "react";
import { CountdownTimer } from "./components";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as SmallLogo } from "../../assets/small-logo.svg";
import qrCode from "../../assets/qr-code.png";
import googlePlay from "./assets/googlePlay.png";
import appStore from "./assets/appStore.png";
import styles from "./styles.module.scss";

const PreviewPage = () => {
  return (
    <div className={styles.previewPage}>
      <div className={styles.info}>
        <div className={styles.infoTop}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.smallLogo}>
            <SmallLogo />
          </div>
          <h1>Мой край</h1>
          <span>До запуска веб-версии</span>
          <CountdownTimer />
        </div>
        <div className={styles.bottomInfo}>
          <span>Откройте мир с нашим приложением</span>
          <div className={styles.mobileInfo}>
            <img src={qrCode} alt="" />
            <div className={styles.buttons}>
              <a href="https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%B9-%D0%BA%D1%80%D0%B0%D0%B9-%D1%83%D0%B7%D0%BD%D0%B0%D0%B9-%D1%81%D0%B2%D0%BE%D0%B9-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD/id1544924368">
                <img src={appStore} alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=ru.tech_reliab.myhomeland&hl=ru&gl=US&pli=1">
                <img src={googlePlay} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PreviewPage };

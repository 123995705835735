import React from 'react';
import { PreviewPage } from './pages';

function App() {
  return (
    <PreviewPage />
  );
}

export default App;
